<template>
  <div style="background-color: white; background-size: 100% 100%;">
    <div :class="`row ${isMobile ? 'mx-1 mt-2 mb-10' : 'mx-10 mt-5 mb-10'}`" v-if="eventInfo && tempInfo && ticketList">
      <div class="col-sm-7 mx-auto">
        <v-card :class="`mt-5 box-white d-flex flex-column ${isMobile ? 'p-3' : 'p-10'}`">
          <span class="font-bold font-25">{{ eventInfo.name }}</span>
          <v-img class="my-5 rounded-20 event-image" :src="hostname === 'localhost' && FOR_MA ? assets.testImage1 : eventInfo.photoUrl" />
          <div class="vertical-center">
            <i class="flaticon2-pin-1 font-15 color-purple"></i>
            <span class="ml-2 font-15 color-purple">{{ eventInfo.hideAddress ? $t('private') : eventInfo.address }}</span>
          </div>
          <div class="mt-2 vertical-center">
            <i class="fa fa-calendar-alt font-15 color-blue"></i>
            <span class="ml-2 font-15 color-blue">{{ getDateStringFromTimestamp(eventInfo.startAt) }} - {{ getDateStringFromTimestamp(eventInfo.endAt) }}</span>
          </div>
          <span :class="`mt-5 ${isMobile ? 'font-15' : 'font-18'}`" v-html="eventInfo.description" v-if="isEllipsized && isExpand" v-linkified></span>
          <span id="spanDescription" v-html="eventInfo.description" :class="`mt-5 ellipsis-2 ${isMobile ? 'font-15' : 'font-18'}`" v-else v-linkified></span>
          <div class="d-flex justify-content-end" v-if="isEllipsized">
            <v-btn icon @click="isExpand = !isExpand">
              <i :class="`fa fa-arrow-circle-${isExpand ? 'up' : 'down'} font-30 color-purple`"></i>
            </v-btn>
          </div>
        </v-card>
      </div>
      <div class="col-sm-5" v-if="ticketList.length > 0">
        <div class="mt-5 p-5 box-white">
          <p>&nbsp;</p>
          <span class="font-12" v-if="canAcceptTicket() && !isLinkExpired()">{{ $t('desc_assigned_vip_ticket_unlock_all', [totalQuantity]) }}</span>
          <div class="mt-5 mx-auto w-75 div-divider-h" v-if="canAcceptTicket() && !isLinkExpired()" />
          <div class="d-flex flex-column mb-1" v-for="(item, index) in ticketList" :key="(item, index)">
            <div class="mt-3 d-flex justify-content-between vertical-center">
              <span class="col-sm-6 font-bold">{{ item.name }}</span>
              <span class="col-sm center">{{ getPriceFormat(item.price) }}</span>
              <div class="col-sm-3 d-flex justify-content-between vertical-center" v-if="!isLinkExpired()">
                <v-btn icon @click="setQuantity(index, false)" :disabled="item.quantity < 1">
                  <img class="ml-1" :height="isMobile ? 20 : 25" :src="assets.minusEnabled" v-if="item.quantity > 0" />
                  <img class="ml-1" :height="isMobile ? 20 : 25" :src="assets.minusDisabled" v-else />
                </v-btn>
                <span :class="`w-50px center font-bold mx-3 ${isMobile ? 'font-15' : 'font-18'}`">{{ `${getDecimalFormat(item.quantity)} / ${getDecimalFormat(item.assignedQuantity)}` }}</span>
                <v-btn icon @click="setQuantity(index, true)">
                  <img class="ml-1" :height="isMobile ? 20 : 25" :src="assets.plusEnabled" v-if="item.quantity < item.assignedQuantity"/>
                  <img class="ml-1" :height="isMobile ? 20 : 25" :src="assets.plusDisabled" v-else />
                </v-btn>
              </div>
            </div>
            <span class="mx-4 mt-2" v-if="canAcceptTicket() && item.description">{{ item.description }}</span>
            <div class="mt-3 mx-auto w-75 div-divider-h" v-if="index < ticketList.length - 1" />
          </div>
          <p>&nbsp;</p>
        </div>
        <div class="my-5 d-flex justify-content-center" v-if="isLinkExpired()">
          <span class="my-5 font-15 center">{{ $t('alert_link_is_expired') }}</span>
        </div>
        <div v-else-if="canAcceptTicket()">
          <div class="my-5 p-5 box-white" v-if="!myInfo">
            <span class="ml-2 font-label">{{ $t('email') }}</span>
            <input class="mt-1 form-control" v-model="email" onpaste="return false;" oninput="this.value = this.value.replace(/[ ]/g,'');" />
            <br/>
            <span class="ml-2 font-label">{{ $t('repeat_email') }}</span>
            <input class="mt-1 form-control" v-model="repeat_email" onpaste="return false;" oninput="this.value = this.value.replace(/[ ]/g,'');" />
            <br/>
            <span class="my-2 font-label color-blue">{{ $t('desc_enter_email_exactly_required_for_next_login') }}</span>
          </div>
          <div class="my-5 d-flex justify-content-end">
            <v-btn class="button-normal" @click="acceptTicket()" :disabled="ticketList.filter(element => element.quantity > 0).length < 1">
              <span class="px-10">{{ $t('accept_ticket') }}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { firebaseAuth, functions, firestore } from '../../../main';
import { onSnapshot, doc, query, collection, where } from 'firebase/firestore';
import { isFutureTimestamp, getDateStringFromTimestamp, getDecimalFormat, getPriceFormat, getDiffFromTimestamp, zeroPad, isValidEmail, showLoading, showFunctionError } from '../../../functions';

import testImage1 from '@/assets/images/test_image1.jpg';
import minusDisabled from '@/assets/images/minus-disabled.png';
import minusEnabled from '@/assets/images/minus-enabled.png';
import plusDisabled from '@/assets/images/plus-disabled.png';
import plusEnabled from '@/assets/images/plus-enabled.png';

export default {
  name: 'VIPTicketLink',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
  },
  data() {
    return {
      email: '',
      repeat_email: '',
      referenceId: '',
      isEllipsized: false,
      isExpand: false,
      fbqInited: false,
      ttqInited: false,
      eventInfo: null,
      tempInfo: null,
      ticketList: null,
      totalQuantity: 0,
      snapEventInfo: null,
      snapTempInfo: null,
      snapTicketList: null,
      hostname: window.location.hostname,
      assets: {
        testImage1,
        minusDisabled,
        minusEnabled,
        plusDisabled,
        plusEnabled
      }
    };
  },
  mounted() {
    if (firebaseAuth.currentUser) {
      this.loadData();
    } else {
      firebaseAuth.onAuthStateChanged(user => {
        if (user) {
          this.loadData();
        }
      });
    }
  },
  destroyed() {
    if (this.snapEventInfo) {
      this.snapEventInfo();
    }
    if (this.snapTempInfo) {
      this.snapTempInfo();
    }
    if (this.snapTicketList) {
      this.snapTicketList();
    }
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    zeroPad,
    getDateStringFromTimestamp,
    loadData() {
      if (this.$route.params.eventId && this.$route.params.time) {
        this.getEventInfoTask(this.$route.params.eventId);
        this.getTempInfoTask(`VIP-${this.$route.params.eventId}-${this.$route.params.time}`);
        for (let i=1; i<5; i++) {
          setTimeout(() => {
            this.checkEllipsized();
          }, 500 * i);
        }
      } else {
        this.$toast.error(this.$t('alert_unexpected_error'));
        this.$router.push(`/live_events`);
      }
    },
    checkEllipsized() {
      const spanDescription = document.getElementById('spanDescription');
      if (spanDescription) {
        this.isEllipsized = spanDescription.offsetHeight < spanDescription.scrollHeight;
      } else {
        this.isEllipsized = false;
      }
    },
    canAcceptTicket() {
      if (this.myInfo && this.myInfo.userType === this.USER_ORGANIZER) {
        return false;
      } else {
        if (this.eventInfo && this.tempInfo) {
          return isFutureTimestamp(this.eventInfo.endAt);
        } else {
          return false;
        }
      }
    },
    isLinkExpired() {
      return getDiffFromTimestamp(this.tempInfo.createdAt) > 3600 * 24 * 1000;
    },
    setQuantity(index, isPlus) {
      if (index >= 0 && index < this.ticketList.length) {
        const ticketInfo = this.ticketList[index];
        if (isPlus) {
          if (ticketInfo.quantity < ticketInfo.assignedQuantity) {
            this.ticketList[index].quantity++;
          }
        } else {
          const minQuantity = ticketInfo.minQuantity > 0 ? ticketInfo.minQuantity : 1;
          if (ticketInfo.quantity > 0) {
            this.ticketList[index].quantity--;
          }
        }
      }
    },
    acceptTicket() {
      const details = [];
      this.ticketList.filter(element => element.quantity > 0).forEach(ticketInfo => {
        details.push({
          ticketId: ticketInfo.ticketId,
          name: ticketInfo.name,
          price: ticketInfo.price,
          quantity: ticketInfo.quantity,
          eventDays: ticketInfo.eventDays || 0
        })
      });
      if (details.length > 0) {
        if (this.myInfo) {
          this.doAcceptTicket(details, this.myInfo.userId);
        } else {
          this.getUserOrRegisterGuestTask(details);
        }
      } else {
        this.$toast.error(this.$t('alert_unexpected_error'));
      }
    },
    doAcceptTicket(details, buyerId) {
      this.$gtag.event('add_to_cart', {
        'event_name': this.eventInfo.name
      });
      if (this.fbqInited) {
        window.fbq('track', `AddToCart: [${this.eventInfo.name}]`);
      }
      if (this.ttqInited) {
        window.ttq.track('AddToCart', {
          description: `Event: ${this.eventInfo.name}`
        });
      }
      this.acceptTicketTask(details, buyerId);
    },
    getEventInfoTask(eventId) {
      if (this.snapEventInfo) {
        return;
      }
      this.snapEventInfo = onSnapshot(doc(firestore, 'event', eventId), doc => {
        const eventInfo = doc.data();
        if (eventInfo) {
          if (eventInfo.deletedAt) {
            this.$toast.error(this.$t('alert_this_event_has_been_deleted'));
            this.$router.push(`/live_events`);
          } else {
            if (eventInfo.facebookPixelId && !this.fbqInited) {
              window.fbq('init', eventInfo.facebookPixelId);
              window.fbq('track', `PageView: [${eventInfo.name}]`);
              this.fbqInited = true;
            }
            if (eventInfo.tikTokPixelId && !this.ttqInited) {
              window.ttq.load(eventInfo.tikTokPixelId);
              window.ttq.page();
              window.ttq.track('ViewContent', {
                description: `Event: ${eventInfo.name}`
              });
              this.ttqInited = true;
            }
            this.eventInfo = eventInfo;
            setTimeout(() => {
              this.checkEllipsized();
            }, 500);
          }
        } else {
          this.$toast.error(this.$t('no_data_found'));
          this.$router.push(`/live_events`);
        }
      });
    },
    getTempInfoTask(referenceId) {
      if (this.snapTempInfo) {
        return;
      }
      this.referenceId = referenceId;
      this.snapTempInfo = onSnapshot(doc(firestore, 'transactionTemp', referenceId), doc => {
        const tempInfo = doc.data();
        if (tempInfo) {
          this.tempInfo = tempInfo;
          this.getTicketListTask(tempInfo.eventId);
        } else {
          this.$toast.error(this.$t('no_data_found'));
          this.$router.push(`/live_events`);
        }
      });
    },
    getTicketListTask(eventId) {
      if (this.snapTicketList) {
        return;
      }
      const q = query(collection(firestore, 'ticket'), where('eventId', '==', eventId));
      this.snapTicketList = onSnapshot(q, querySnapshot => {
        const ticketList = [];
        var totalQuantity = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (this.tempInfo.details) {
            const detail = this.tempInfo.details.find(element => element.ticketId === data.ticketId);
            if (detail) {
              data['assignedQuantity'] = detail.quantity;
              data['quantity'] = 0;
              totalQuantity += detail.quantity;
              ticketList.push(data);
            }
          }
        });
        ticketList.sort((a, b) => (a.price > b.price) ? 1 : -1);
        this.ticketList = ticketList;
        this.totalQuantity = totalQuantity;
      });
    },
    getUserOrRegisterGuestTask(details) {
      if (!this.email) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('email')]));
        return;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.$t('alert_invalid_email'));
        return;
      }
      if (!this.repeat_email) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('repeat_email')]));
        return;
      }
      if (this.email !== this.repeat_email) {
        this.$toast.error(this.$t('alert_emails_not_match'));
        return;
      }
      const params = {
        forGuest: true,
        email: this.email
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'registerUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        let userInfo;
        try {
          userInfo = JSON.parse(response.data);
        } catch(exception) {
          userInfo = null;
        }
        if (userInfo && userInfo.userId) {
          localStorage.setItem('eventboxGuestInfo', JSON.stringify(userInfo));
          this.doAcceptTicket(details, userInfo.userId);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    acceptTicketTask(details, buyerId) {
      const loader = showLoading(this, 1);
      const params = {
        acceptVIPTicketLink: true,
        referenceId: this.referenceId,
        buyerId: buyerId,
        details: details
      };
      const func = httpsCallable(functions, 'buyTicket');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_success'));
          this.$router.push(`/my_tickets`);
        } else if (response.data === this.RESULT_DUPLICATED_TRANSACTION) {
          this.$toast.error(this.$t('alert_link_is_already_activated'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>